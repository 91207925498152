<template>
    <div class="container mt-5">
        <div class="wizard">
            <ul class="nav nav-tabs">
                <li v-for="(tab, index) in steps" :key="index" class="nav-item">
                    <button
                            :class="['nav-link', { active: currentStep === index }]"
                            @click="changeStep(index)"
                    >
                        {{ tab.name }}
                    </button>
                </li>
            </ul>
            <div class="tab-content mt-3">
                <!-- Step 1: Upload Excel -->
                <div v-if="currentStep === 0">
                    <h3>Subir Archivo Excel</h3>
                    <FileUploader
                            url="stores/concilia/analyze-excel"
                            :accepted-mime="[
        'image/*',
        'file/.dcm',
        'application/pdf',
        'application/x-xls',
        'application/vnd.ms-excel',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/octet-stream',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]"
                            remove-file-on-finish="1"
                            :extra-data-form="{ cotizacionId: cotizacionId }"
                            @success="handleFileUpload"
                            @error="handleUploadError"
                    ></FileUploader>
                    <button class="btn btn-primary mt-3" :disabled="!fileUploaded" @click="analyzeFile">
                        Analizar Archivo
                    </button>
                </div>
                <!-- Step 2: Show Analysis Results -->
                <div v-if="currentStep === 1">
                    <h3>Resultados del Análisis</h3>
                    <div>
                        <h5>Pólizas mal escritas</h5>
                        <table class="table">
                            <thead>
                            <tr>
                                <th>DPI</th>
                                <th>Póliza (Excel)</th>
                                <th>Póliza (Base de Datos)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in analysisData.mismatchedPolicies" :key="item.dpi">
                                <td>{{ item.dpi }}</td>
                                <td>{{ item.excelPolicy }}</td>
                                <td>{{ item.dbPolicy }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <h5>Montos incorrectos</h5>
                        <table class="table">
                            <thead>
                            <tr>
                                <th>DPI</th>
                                <th>Póliza</th>
                                <th>Monto (Excel)</th>
                                <th>Monto (Base de Datos)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in analysisData.incorrectAmounts" :key="item.dpi">
                                <td>{{ item.dpi }}</td>
                                <td>{{ item.policyNumber }}</td>
                                <td>{{ item.excelAmount }}</td>
                                <td>{{ item.dbAmount }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Additional steps -->
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import API from "/src/core/Api";
import {store} from "/src/store";
import Numbro from 'numbro';
import dayjs from "dayjs";
import axios from 'axios';
import visible from 'axios';
import VPerfectSignature from 'v-perfect-signature'
import {FormWizard, TabContent, WizardStep} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import FileUploader from "src/components/files/FileUploader.vue";


import settingLayout from "../../components/settings/settingLayout.vue";

export default {
    components: {
        FileUploader,
    },
    data() {
        return {
            currentStep: 0,
            steps: [
                { name: "Subir Archivo" },
                { name: "Analizar Archivo" },
                { name: "Descargar Pólizas" },
                { name: "Refacturar" },
                { name: "Crear Ventas Nuevas" },
                { name: "Verificar Datos Faltantes" },
                { name: "Facturar Cuotas" },
            ],
            cotizacionId: null, // Update this variable dynamically if needed
            fileUploaded: false,
            analysisData: {
                mismatchedPolicies: [],
                incorrectAmounts: [],
                newSales: [],
                missingFromExcel: [],
            },
        };
    },
    methods: {
        changeStep(index) {
            this.currentStep = index;
        },
        handleFileUpload(response) {
            if (response && response.data && response.data.fileId) {
                this.fileUploaded = true;
                this.cotizacionId = response.data.fileId;
            } else {
                alert("No se pudo subir el archivo correctamente.");
            }
        },
        handleUploadError(error) {
            console.error("Error al subir el archivo:", error);
            alert("Error al subir el archivo. Verifica el formato e inténtalo de nuevo.");
        },
        async analyzeFile() {
            try {
                const response = await API.send("POST", "/api/analyze-excel", {
                    fileId: this.cotizacionId,
                });
                this.analysisData = response.data;
                this.changeStep(1);
            } catch (error) {
                console.error("Error al analizar el archivo:", error);
                alert("Error al analizar el archivo. Inténtalo de nuevo.");
            }
        },
    },
};
</script>

<style>
/* Estilos básicos para el wizard */
.wizard .nav-tabs {
    border-bottom: 2px solid #ddd;
}
.wizard .nav-item .nav-link.active {
    background-color: #007bff;
    color: white;
}
</style>
