<template>
    <setting-layout :active="41">
        <div class="modals-box">
            <div v-if="showCombineModal" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
                                                <div class="modal-dialog modal-xl">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="modal-cita-titulo">Combinar Clientes</h5>

                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="row">
                                                                <div class="col-12 mt-2 mb-4">
                                                                    <i class="fa-solid fa-circle-info text-primary"></i> Al combinar clientes, los datos e historial de todos los clientes seleccionados se le adjuntan
                                                                     al cliente principal y los demas clientes son eliminados del sistema.
                                                                </div>
                                                                <p v-if="principalClient.id===0" class="text-danger col-12 my-1">
                                                                    <i class="fa-regular fa-user text-danger"></i> Seleccione el cliente principal
                                                                </p>
                                                                <p v-else class="col-12 my-1">
                                                                    <i class="fa-solid fa-user text-success"></i> El cliente <span class="text-dark">{{`${principalClient.nombres} ${principalClient.apellidos}`}}</span> con <span class="text-dark">{{`CUI ${principalClient.cui_passport}`}}</span> y con <span class="text-dark">ID</span> en sistema  <span class="text-dark">{{`${principalClient.id}`}}</span> ha sido seleccionado como cliente pricipal.
                                                                </p>
                                                            
                                                                <div v-if="clientToCombine.length" class="col-12 d-flex gap-2 flex-wrap justify-content-around">
                                                                    <template v-for="(item, index) in clientToCombine" :key="index">
                                                                            <div class="card m-2 position-relative" style="width:240px;">
                                                                                <i @click="cleanClientToCombine(item.id)" class="fa-solid fa-circle-xmark p-2 text-danger position-absolute top-0 zindex-1"></i>
                                                                                <div class="card-body w-100">
                                                                                <h6 class="card-title text-truncate w-100 m-0 small mb-1">{{ item.nombres + " " + item.apellidos}}</h6>
                                                                                <p class="card-text m-0 small"><span class="font-weight-bold">CUI: </span> {{item.cui_passport}}</p>
                                                                                <p class="card-text text-truncate w-100 m-0 small"><span class="font-weight-bold">Correo: </span>{{item.email}}</p>
                                                                                <p class="card-text text-truncate w-100 m-0 small"><span class="font-weight-bold">Fecha de creacion: </span>{{item.createdAt}}</p>
                                                                                <p class="card-text text-truncate w-100 m-0 small"><span class="font-weight-bold">Sistem ID: </span>{{item.id}}</p>
                                                                                <div @click="selectPrincipalClient(item)" v-if="principalClient.id === 0" class=" cursor-pointer d-flex justify-content-between align-items-center p-2">
                                                                                    <p class="d-flex align-items-center m-0 mr-2">Seleccionar</p>
                                                                                    <i class="fa-regular fa-circle-check fa-2x text-success"></i>
                                                                                </div>
                                                                                <div @click="selectPrincipalClient(item)" v-if="(principalClient.id===item.id) && (principalClient.id !== 0)" class=" cursor-pointer d-flex justify-content-between align-items-center p-2">
                                                                                    <p class="d-flex align-items-center m-0 mr-2">Seleccionado</p>
                                                                                    <i class="fa-solid fa-circle-check fa-2x text-success"></i>
                                                                                </div>
                                                                                <div @click="selectPrincipalClient(item)" v-if="(principalClient.id!==item.id) && (principalClient.id !== 0)" class=" cursor-pointer d-flex justify-content-between align-items-center p-2">
                                                                                    <p class="d-flex align-items-center text-muted m-0 mr-2">Descartado</p>
                                                                                    <i class="fa-solid fa-ban fa-2x text-muted"></i>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                    </template>
                                                                </div>
                                                                <div v-else class="col-12">
                                                                    <p class="text-danger col-12 my-1">
                                                                    *Hay un error en la seleccion de los clientes
                                                                </p>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button @click="doCombineClients" type="button" class="btn btn-success" :disabled="principalClient.id===0">Finalizar</button>
                                                            <div class="btn btn-secondary" @click="handleMixModal" data-dismiss="modal">Cerrar</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
        </div>
        <div class="card">
            <div class="card-header w-100">
                <div class="d-flex flex-column justify-content-start flex-md-row justify-content-md-between w-100">
                    <h4 class="card-title">Listado de clientes</h4>

                    <div class="">
                        <router-link to="/client/0" class="btn btn-primary btn-sm mr-2">
                            <i class="fa fa-plus-circle"></i> Agregar
                        </router-link>
                        <router-link to="/clients/add/massive" class="btn btn-primary btn-sm mr-2">
                            <i class="fa fa-plus-circle"></i> Carga masiva
                        </router-link>
                        <div v-if="clientToCombine.length > 1" class="btn btn-success btn-sm" @click="handleMixModal">
                            <i class="fa-solid fa-users-rays"></i>
                            Combinar clientes
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h6 class="w-100 px-1 py-3 font-weight-bold">Buscar por:</h6>
                        <div class="row">
                            <div class="col-12 col-lg-10">
                                <div class="row">
                                    <div class="col-12 col-lg-10">
                                        <div class="input-group mb-1">
                                            <input type="text" v-model="searchValue" class="form-control" v-on:keyup.enter="doSearch" placeholder="Ejemplo: Juan...">
                                            <div class="input-group-prepend">
                                                <select v-model="searchTerm" class="form-control">
                                                    <option :value="0" disabled>Seleccione el tipo de filtro para la búsqueda</option>
                                                    <option :value="1">DPI o Pasaporte</option>
                                                    <option v-if="AuthGetUserInfo.layout === 'medico'" :value="2">
                                                        No. Expediente medico
                                                    </option>
                                                    <option v-else :value="2">
                                                        ID
                                                    </option>
                                                    <option :value="3">Nombre</option>
                                                    <option :value="4">Apellido</option>
                                                    <option :value="5">Correo electrónico</option>
                                                    <option v-if="AuthGetUserInfo.layout === 'medico'" :value="6">
                                                        No. Expediente físico
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <small v-if="showHelper" class="text-danger">
                                            *Seleccione un filtro para la búsqueda
                                        </small>
                                        <small v-else class="text-primary">
                                            *Asegúrese de ingresar el dato correcto respecto al filtro
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2 text-right">
                                <button type="button" class="btn btn-primary w-100" @click="doSearch"><i class="fa fa-search mr-2"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="showHelper" class="d-flex align-items-center px-3 py-2 text-primary">
                        <i class="fa-regular fa-lightbulb mr-2"></i><small>Es necesario ingresar datos para la busqueda</small>
                    </div>
                    <div  v-if="itemList.length > 0" class="col-12">
                        <nav aria-label="navigation" class="px-1 py-2">
                            <ul class="pagination">
                                <li class="page-item" v-for="item in pagination" :key="'page' + item">
                                    <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                    <div v-else class="page-link">{{ item }}</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="form">
                    <div v-if="itemList.length === 0" class="d-flex justify-content-around align-items-center" >
                        <div class="text-muted d-flex justify-content-center align-items-center mx-5 my-1 py-5 border-top w-75">
                            <i class="fa-solid fa-circle-exclamation"></i>
                            <p class="m-0 ml-2">No se encontraron resultados</p>
                        </div>
                    </div>
                    <ul v-else>
                        <li v-for="item in itemList" :key="item.id" class="h-50">
                            <div class="listItem" :class="selectItemBackground(item)">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div>
                                            <h5 class="mt-0 mb-1">{{item.nombres}} {{item.apellidos}}</h5>
                                            <hr style="border-top: 1px solid #eee;">
                                            <div v-if="AuthGetUserInfo.layout === 'medico'"><b>No.Expediente Medico:</b> {{ item.id }}</div>
                                            <div v-else><b>ID:</b> {{ item.id }}</div>
                                            <div v-if="AuthGetUserInfo.layout === 'medico'"><b>No.Expediente físico:</b> {{ item.num_expediente_fisico }}</div>
                                            <div><b>Correo electrónico:</b> {{ item.email }}</div>
                                            <div v-if="item.edad"><b>Edad:</b> {{ item.edad }} años</div>
                                            <div v-if="item.cui_passport"><b>Documento de identificación</b> {{ item.cui_passport }}</div>
                                            <div>
                                                <b>Género:</b>
                                                <span v-if="item.genero === 'M'">Hombre</span>
                                                <span v-else-if="item.genero === 'F'">Mujer</span>
                                                <span v-else>No especificado</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 text-right">
                                        <div class="edit-options">
                                            <div class="group">
                                                <!-- <router-link class="p-0 m-0" :to="'/client/'+item.id"> -->
                                                        <i @click="gotoPage(item.id)" class="fa-solid fa-circle-info text-primary cursor-pointer p-1" title="Ver detalles"></i>
                                                        <i @click="deleteClient(item.id)" class="fa-solid fa-trash-can text-danger cursor-pointer p-1" title="Eliminar cliente"></i>
                                                        <i v-if="(checkSearch() && searching)" @click="addClientToCombine(item)" class="fa-solid fa-users-rays cursor-pointer p-1" title="Combinar cliente"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<style scoped>
.edit-options{
    font-size: 20px;
   
}´
.group{
    display: flex;
    justify-content: center;
}

</style>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            showCombineModal: false,
            searching: false,
            showHelper:false,
            itemList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filterDPI: '',
            filterID: '',
            filterNombre: '',
            filterApellido: '',
            filterEmail: '',
            filterNumExpFisico:'',
            searchValue: '',
            searchTerm: 0,
            clientToCombine: [],
            principalClient:{
                id:0
            }
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo'
        }),
    },
    mounted() {
        this.loadItems();
    },
    watch: {
    },
    methods: {
        dayjs,
        doCombineClients(){
            const self = this;
            
            let principalId = self.principalClient.id;
            let discardClients = self.clientToCombine
            .filter((item) => item.id !== principalId)
            .map((item) => item.id);

            
            API.showConfirm('¿Está seguro que de combinar estos clientes?', 'Esta acción no se puede deshacer y los todos los clientes descartados se eliminaran permanentemente', function () {
                API.send('POST', 'clients/combinar', {
                    id: principalId,
                    clientsToCombine: discardClients
                },
                    function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                        self.successCombine();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })

            },
        cleanClientToCombine(id){
            const self = this;
            let filter = self.clientToCombine.filter((item) => item.id !== id);
            self.clientToCombine = filter;
        },
        selectPrincipalClient(item){
            if(this.principalClient.id !== item.id){
                return this.principalClient = item;
            }else{
                return this.principalClient = {
                    id:0
                };
            }
        },
        successCombine(){
            this.showCombineModal=false;
            this.searching=false;
            this.clientToCombine=[];
            this.principalClient={
                id:0
            }
        },
        selectItemBackground(item){
            if(this.clientToCombine.find(e => e.id == item.id)){
                return "bg-success"
            }else{
                return ""
            }
        },
        addClientToCombine(item){
            if(!this.clientToCombine.find(e => e.id == item.id)){
                this.clientToCombine.push(item);
            }else{
                let filter = this.clientToCombine.filter(e => e.id !== item.id)
                this.clientToCombine=filter;
            }
        },
        handleMixModal(){
            this.showCombineModal=!this.showCombineModal;
        },
        checkSearch() {
            if (this.filterDPI !== '' || this.filterNombre !== '' || this.filterApellido !== '' || this.filterEmail !== '') {
                return true;
            }else{
                return false;
            }
        },
        doSearch() {
            if (this.searchTerm === 0) {
                this.showHelper = true;
                return;
            }

            this.searching = true;
            this.showHelper = false;

            // Reset all filters
            this.filterDPI = '';
            this.filterID = '';
            this.filterNombre = '';
            this.filterApellido = '';
            this.filterEmail = '';

            // Set the appropriate filter based on the selected search term
            switch (this.searchTerm) {
                case 1:
                    this.filterDPI = this.searchValue;
                    break;
                case 2:
                    this.filterID = this.searchValue;
                    break;
                case 3:
                    this.filterNombre = this.searchValue;
                    break;
                case 4:
                    this.filterApellido = this.searchValue;
                    break;
                case 5:
                    this.filterEmail = this.searchValue;
                    break;
                case 6:
                    this.filterNumExpFisico = this.searchValue;
                    break;
                default:
                    break;
            }

            // Perform the search
            if (this.searchValue === '') {
                this.searching = false;
                this.showHelper = true;
            } else {
                this.pageSelected = 1;
                this.limit = 20;
                this.loadItems();
            }
        },
        loadItems() {
            const self = this;

            self.itemList = [];
            self.pagination = [];

            API.send('POST', 'clients/get-list', {
                    page: self.pageSelected,
                    perPage: self.limit,
                    filterDPI: self.filterDPI,
                    filterID: self.filterID,
                    filterNombre: self.filterNombre,
                    filterApellido: self.filterApellido,
                    filterEmail: self.filterEmail,
                    filterNumExpFisico: self.filterNumExpFisico
                },
                function (data) {
                    if (data.status) {
                        self.itemList = data.data.clientes;
                        self.pagination = data.data.paginas;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        }, 
        deleteClient(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar este cliente?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'clients/delete/' + id, {},
                    function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })
        },
        gotoPage(id){
            this.$router.push(`/client/${id}`);
        }
    },
};
</script>
