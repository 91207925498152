<template>
    <div class="container mt-4">
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item" v-for="(step, index) in steps" :key="index" v-if="isLoggedIn || index === 0">
                        <div
                                class="nav-link"
                                :class="{ active: currentStep === index + 1 }"
                                @click="goToStep(index + 1)"
                        >
                            {{ step.title }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div v-show="currentStep === 1">
                    <h2>Login</h2>
                    <div class="form-group">
                        <label for="email" class="form-label">Correo Electrónico:</label>
                        <input type="email" id="email" v-model="email" class="form-control" required />
                    </div>
                    <div class="form-group">
                        <label for="password" class="form-label">Contraseña:</label>
                        <input type="password" id="password" v-model="password" class="form-control" required />
                    </div>
                    <div class="btn btn-primary" @click="validateLogin()"><i class="fa fa-arrow-circle-right"></i></div>
                </div>
                <div v-show="currentStep === 2 && isLoggedIn">
                    <h2>Subir Archivo</h2>
                    <div class="btn btn-secondary w-100 mt-2" @click="downloadTemplate">
                        <i class="fa fa-file-excel"></i> Plantilla
                    </div>
                    <div class="dropzone" @drop.prevent="handleDrop" @dragover.prevent @click="triggerFileInput">
                        <input type="file" @change="handleFileUpload" accept=".xlsx, .xls" class="file-input" ref="fileInput" />
                        <p>Arrastra y suelta tu archivo aquí, o haz clic para seleccionar un archivo</p>
                    </div>

                    <div class="btn btn-secondary" @click="prevStep"><i class="fa fa-arrow-circle-left"></i></div>
                    <div class="btn btn-primary" @click="nextStep"><i class="fa fa-arrow-circle-right"></i></div>
                </div>
                <div v-show="currentStep === 3 && isLoggedIn">
                    <h2>Seleccionar Producto</h2>
                    <div class="form-group">
                        <label for="productSelect" class="form-label">Selecciona el Producto:</label>
                        <select id="productSelect" class="form-control mb-3" v-model="selectedProduct">
                            <option v-for="product in products" :key="product.id" :value="product.sku">
                                {{ product.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="boleta" class="form-label">Número de Boleta:</label>
                        <input type="text" id="boleta" v-model="boleta" class="form-control" />
                    </div>
                    <div class="btn btn-secondary" @click="prevStep"><i class="fa fa-arrow-circle-left"></i></div>
                    <div class="btn btn-primary" @click="nextStep"><i class="fa fa-arrow-circle-right"></i></div>
                </div>
                <div v-show="currentStep === 4 && isLoggedIn">
                    <h2>Procesar</h2>
                    <div class="btn btn-primary w-100" @click="processPolicies">
                        <i class="fa fa-cogs"></i> Procesar Pólizas
                    </div>

                    <div class="btn btn-secondary" @click="prevStep"><i class="fa fa-arrow-circle-left"></i></div>
                    <div class="btn btn-primary" @click="nextStep"><i class="fa fa-arrow-circle-right"></i></div>
                </div>
                <div v-show="currentStep === 5 && isLoggedIn">
                    <h2>Confirmar y Aplicar Pago</h2>
                    <div v-if="jsonResults.total">
                        <p class="text-center">Monto Total: {{ jsonResults.total }}</p>
                        <div class="btn btn-success w-100" @click="applyPayment" :disabled="isApplyingPayment">
                            <span v-if="isApplyingPayment" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="!isApplyingPayment"><i class="fa fa-check"></i> Aplicar Pago</span>
                            <span v-if="isApplyingPayment">Aplicando...</span>
                        </div>
                    </div>
                    <div class="btn btn-secondary" @click="prevStep"><i class="fa fa-arrow-circle-left"></i></div>
                </div>
            </div>
        </div>

        <!-- Animación de progreso -->
        <div v-if="isProcessing" class="d-flex flex-column align-items-center mt-4">
            <div class="progress-circle">
                <svg viewBox="0 0 36 36">
                    <path
                            class="circle-bg"
                            d="M18 2.0845
                           a 15.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                            class="circle"
                            :style="{ strokeDasharray: `${progressPercentage}, 100` }"
                            d="M18 2.0845
                           a 15.9155 15.9155 0 0 1 0 31.831
                           a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                </svg>
                <span class="circle-text">{{ progressPercentage.toFixed(0) }}%</span>
            </div>
            <p class="text-center mt-3">Tiempo restante: {{ estimatedTime }} segundos</p>
        </div>

        <!-- Resultados -->
        <div v-if="jsonResults.length" class="mt-4">
            <div class="alert alert-success text-center" role="alert">
                <strong>¡Procesamiento completado!</strong> Puedes descargar los archivos generados a continuación.
            </div>
            <div class="d-flex justify-content-around">
                <div class="btn btn-outline-info" @click="downloadJSON">
                    <i class="fa fa-file-code-o"></i> Descargar JSON
                </div>
                <div class="btn btn-outline-success" @click="downloadExcel">
                    <i class="fa fa-file-excel-o"></i> Descargar Excel
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";
import { saveAs } from "file-saver";
import API from "/src/core/Api";
import Numbro from "numbro";
import Tools from "src/core/Tools";
import { mapGetters } from "vuex";
import FileUploader from "src/components/files/FileUploader.vue";

export default {
    components: {
        FileUploader,
    },
    data() {
        return {
            currentStep: 1,
            steps: [
                { title: 'Login' },
                { title: 'Subir Archivo' },
                { title: 'Seleccionar Producto' },
                { title: 'Procesar' },
                { title: 'Confirmar y Aplicar Pago' },
            ],
            email: '',
            password: '',
            policies: [],

            jsonResults: [],
            isProcessing: false,
            isApplyingPayment: false,
            progressPercentage: 0,
            estimatedTime: 0,
            startTime: null,
            selectedProduct: null,
            products: [],
            boleta: '',
            token: '',
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filter: '',
            searchTerm: 0,
            tokenExpiration: null,
            tokenRefreshInterval: null,
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),
        isLoggedIn() {
            return !!this.token;
        }
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        async fetchProducts() {
            const self = this;
            self.pageSelected = 1;
            self.limit = 10;
            self.filter = '';
            self.searchTerm = 0;

            API.send('GET', 'products/all/1/' + self.pageSelected + '/' + self.limit + '/' + self.searchTerm, {},
                function (data) {
                    if (data.status) {
                        self.products = data.data.productos.map(product => ({
                            id: product.id,
                            name: product.nombre,
                            sku: product.sku,
                        }));
                        self.pagination = data.data.paginas;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                }
            );
        },

        async handleDrop(event) {
            const file = event.dataTransfer.files[0];
            await this.processFile(file);
        },

        async handleFileUpload(event) {
            const file = event.target.files[0];
            await this.processFile(file);
        },

        async processFile(file) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            this.policies = XLSX.utils.sheet_to_json(worksheet);
            this.nextStep(); // Proceed to the next step after successful file upload
        },
        async login() {
            try {
                const response = await axios.post('https://agentes.elroble.com/Cobros/session/login', {
                    usuario: this.email,
                    contrasenia: this.password,
                    origen: 'services'
                });
                this.token = response.data.data.token;
                this.tokenExpiration = new Date(response.data.data.expira);
                this.startTokenRefreshTimer();
                this.nextStep(); // Proceed to the next step after successful login
            } catch (error) {
                console.error('Login failed:', error);
                alert('Login failed. Please check your credentials and try again.');
            }
        },
        startTokenRefreshTimer() {
            const now = new Date();
            const timeUntilExpiration = this.tokenExpiration - now;
            const refreshTime = timeUntilExpiration - 60000; // Refresh 1 minute before expiration

            if (this.tokenRefreshInterval) {
                clearInterval(this.tokenRefreshInterval);
            }

            this.tokenRefreshInterval = setInterval(this.refreshToken, refreshTime);
        },
        async refreshToken() {
            try {
                const response = await axios.post('https://agentes.elroble.com/Cobros/session/login', {
                    usuario: this.email,
                    contrasenia: this.password,
                    origen: 'services'
                });
                this.token = response.data.data.token;
                this.tokenExpiration = new Date(response.data.data.expira);
                this.startTokenRefreshTimer();
            } catch (error) {
                console.error('Token refresh failed:', error);
                alert('Token refresh failed. Please log in again.');
                this.currentStep = 1; // Go back to login step
            }
        },

        downloadTemplate() {
            // Define the headers
            const headers = [
                ["NUM POL", "Encargos Cobranza", "Suma de Pago"]
            ];

            // Create a new workbook and worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(headers);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

            // Write the workbook to a binary string
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the binary string and use saveAs to download it
            const blob = new Blob([wbout], { type: 'application/octet-stream' });
            saveAs(blob, 'template.xlsx');
        },
        handleUploadError(error) {
            console.error("Error al subir el archivo:", error);
            alert("Error al subir el archivo. Verifica el formato e inténtalo de nuevo.");
        },
        cleanSpecialCharacters(value) {
            if (typeof value !== 'string') {
                return String(value);
            }
            return value.replace(/[^0-9.]/g, '');
        },
        async processPolicies() {
            if (!this.selectedProduct) {
                alert('Por favor selecciona un producto.');
                return;
            }

            this.isProcessing = true;
            this.startTime = new Date(); // Registrar el inicio del procesamiento
            const totalPolicies = this.policies.length;
            let totalGlobal = 0;
            const globalRequerimientos = [];
            const errorPolicies = [];

            for (let i = 0; i < totalPolicies; i++) {
                const policy = this.policies[i];
                const pPoliza = this.cleanSpecialCharacters(policy["NUM POL"]);
                const encargosCobranza = parseInt(this.cleanSpecialCharacters(policy["Encargos Cobranza"]));
                const montoTotal = parseFloat(this.cleanSpecialCharacters(policy["Suma de Pago"]));
                const url = `https://agentes.elroble.com/Cobros/v1/findRequerimientosByPoliza?pPoliza=${pPoliza}&pProducto=${this.selectedProduct}&pAfiliada=0`;

                try {
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    });

                    const requerimientos = response.data;

                    if (requerimientos.data && requerimientos.data.length > 0) {
                        const montoPorPago = montoTotal / encargosCobranza;
                        const distribuidos = this.generateRequerimientos(
                            requerimientos.data,
                            montoPorPago,
                            encargosCobranza
                        );
                        globalRequerimientos.push(...distribuidos);
                        totalGlobal += montoTotal;
                    }
                    else {
                        console.warn(`No se encontraron requerimientos para la póliza ${pPoliza}`);
                        errorPolicies.push(policy);
                    }

                    // Actualizar progreso y tiempo estimado
                    this.progressPercentage = ((i + 1) / totalPolicies) * 100;
                    const elapsedTime = (new Date() - this.startTime) / 1000; // En segundos
                    const averageTimePerPolicy = elapsedTime / (i + 1);
                    this.estimatedTime = Math.max(
                        Math.round(averageTimePerPolicy * (totalPolicies - (i + 1))),
                        0
                    );
                } catch (error) {
                    console.error(`Error procesando la póliza ${pPoliza}:`, error);
                    errorPolicies.push(policy);
                }
            }

            this.jsonResults = {
                total: totalGlobal.toFixed(2),
                boleta: this.boleta,
                requerimientos: globalRequerimientos,
            };

            if (errorPolicies.length > 0) {
                this.downloadErrorPolicies(errorPolicies);
            }

            this.isProcessing = false; // Finalizar el indicador de procesamiento
        },
        generateRequerimientos(requerimientos, montoPorPago, encargosCobranza) {
            const distribuidos = [];
            const requerimientosAgregados = new Set();

            for (let i = 0; i < requerimientos.length && distribuidos.length < encargosCobranza; i++) {
                const requerimiento = requerimientos[i];
                if (!requerimientosAgregados.has(requerimiento.requerimiento)) {
                    distribuidos.push({
                        numero: requerimiento.requerimiento,
                        serie: requerimiento.serie,
                        monto: montoPorPago.toFixed(2),
                    });
                    requerimientosAgregados.add(requerimiento.requerimiento);
                }
            }

            return distribuidos;
        },
        downloadJSON() {
            const jsonString = JSON.stringify(this.jsonResults, null, 2);
            const blob = new Blob([jsonString], {type: "application/json"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "RequerimientosGlobales.json";
            link.click();
            URL.revokeObjectURL(url);
        },
        downloadExcel() {
            const worksheet = XLSX.utils.json_to_sheet(this.jsonResults.requerimientos);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "RequerimientosGlobales");
            XLSX.writeFile(workbook, "RequerimientosGlobales.xlsx");
        },
        downloadErrorPolicies(errorPolicies) {
            const worksheet = XLSX.utils.json_to_sheet(errorPolicies);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Errores");
            XLSX.writeFile(workbook, "Errores.xlsx");
        },
        async applyPayment() {
            this.isApplyingPayment = true;
            try {
                const response = await axios.post('https://agentes.elroble.com/Cobros/planillas/energuate', this.jsonResults, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                const responseJson = response.data;
                this.downloadResponseJSON(responseJson);
                alert('Pago aplicado exitosamente');
            } catch (error) {
                console.error('Error aplicando el pago:', error);
                alert('Error aplicando el pago');
            } finally {
                this.isApplyingPayment = false;
            }
        },
        downloadResponseJSON(responseJson) {
            const jsonString = JSON.stringify(responseJson, null, 2);
            const blob = new Blob([jsonString], {type: "application/json"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "RespuestaPago.json";
            link.click();
            URL.revokeObjectURL(url);
        },
        nextStep() {
            this.currentStep++;
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        goToStep(step) {
            this.currentStep = step;
        },
        validateLogin() {
            if (!this.email || !this.password) {
                alert('Por favor ingresa tu correo y contraseña.');
                return false;
            }
            return this.login();
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        onComplete() {
            alert('¡Proceso completado!');
        }
    },
};
</script>

<style scoped>
.nav-pills .nav-link.active {
    background-color: #007bff;
}

/* Estilos para el círculo de progreso */
.progress-circle {
    position: relative;
    width: 100px;
    height: 100px;
}

svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
}

.circle-bg {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke: #007bff;
    stroke-width: 3.8;
    stroke-linecap: round;
    stroke-dasharray: 0, 100;
    transition: stroke-dasharray 0.3s ease;
}

.circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}
.dropzone {
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropzone:hover {
    background-color: #f1f1f1;
}

.file-input {
    display: none;
}

.dropzone p {
    margin: 0;
    font-size: 16px;
    color: #007bff;
}
</style>