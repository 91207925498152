<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Órdenes Encontradas</h4>
            </div>
            <div class="card-body">
                <!-- Búsqueda por DPI, SKU y Certificado -->
                <div class="mb-3">
                    <div class="input-group">
                        <span class="input-group-text p-1 bg-transparent border-0">
                            <i class="fas fa-id-card" style="font-size: 1.2rem; color: var(--primary-color);"></i>
                        </span>
                        <input
                                type="text"
                                id="dpi-search"
                                v-model="search.dpi"
                                class="form-control"
                                placeholder="DPI"
                                @keyup.enter="searchData"
                        />
                        <span class="input-group-text p-1 bg-transparent border-0">
                            <i class="fas fa-box" style="font-size: 1.2rem; color: var(--primary-color);"></i>
                        </span>
                        <div class="form-control p-0">
                            <select
                                    id="sku-search"
                                    v-model="search.sku"
                                    class="form-select border-0"
                            >
                                <option value="" disabled>Selecciona un SKU</option>
                                <option v-for="producto in productos.productos" :key="producto.id" :value="producto.sku">
                                    {{ producto.sku }}
                                </option>
                            </select>
                        </div>
                        <span class="input-group-text p-1 bg-transparent border-0">
                            <i class="fas fa-file-alt" style="font-size: 1.2rem; color: var(--primary-color);"></i>
                        </span>
                        <input
                                type="text"
                                id="certificado-search"
                                v-model="search.certificado"
                                class="form-control"
                                placeholder="Certificado"
                                @keyup.enter="searchData"
                        />
                        <button @click="searchData" class="btn btn-primary p-2">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>

                <!-- Listado de órdenes -->
                <div v-if="orders.length">
                    <h5>Órdenes Encontradas</h5>
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Titular</th>
                            <th>Póliza</th>
                            <th>Monto</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="(orden, index) in orders"
                                :key="index"
                                @click="selectOrder(index)"
                                class="cursor-pointer"
                        >
                            <td>
                                {{ getCampoValor(orden, 'nombres') }} {{ getCampoValor(orden, 'primer-apellido') }}
                            </td>
                            <td>{{ getCampoValor(orden, 'poliza') }}</td>
                            <td>{{ getCampoValor(orden, 'monto') }}</td>
                            <td>
                                <button @click.stop="confirmDelete(index)" class="btn btn-danger btn-sm">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Renderizar secciones de la orden seleccionada -->
                <div v-if="formLoaded" class="mt-4">
                    <form>
                        <div v-for="(seccion, index) in form" :key="index" class="mb-4">
                            <h6 class="text-primary mb-3">{{ seccion.seccion }}</h6>
                            <div class="row g-3">
                                <div v-for="(campo, campoIndex) in seccion.campos" :key="campoIndex" class="col-md-6">
                                    <label :for="campo.slug" class="form-label">{{ campo.label }}</label>
                                    <input
                                            :is="getInputType(campo.valor)"
                                            type="text"
                                            :id="campo.slug"
                                            v-model="campo.valor"
                                            class="form-control"
                                            :placeholder="campo.label"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <button @click="saveData" type="button" class="btn btn-success me-2">
                                Guardar
                            </button>
                            <button @click="resetForm" type="button" class="btn btn-secondary me-2">
                                Resetear
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Modal de confirmación -->
            <div v-if="showModal" class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Confirmar Eliminación</h5>
                            <button type="button" class="btn-close" @click="closeModal"></button>
                        </div>
                        <div class="modal-body">
                            <p>¿Estás seguro de que deseas eliminar esta orden?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="closeModal">Cancelar</button>
                            <button type="button" class="btn btn-danger" @click="deleteOrder">Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>

<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";

export default {
    components: {
        controlComerciosLayout,
    },
    data() {
        return {
            search: { dpi: "", sku: "", certificado: "" },
            orders: [],
            form: [],
            formLoaded: false,
            productos: [],
            showModal: false,
            selectedOrderIndex: null,
        };
    },
    methods: {
        getCampoValor(orden, slug) {
            // Validar si 'orden' tiene la estructura esperada
            if (orden && orden.secciones && Array.isArray(orden.secciones)) {
                // Iterar sobre las secciones para buscar el campo
                for (const seccion of orden.secciones) {
                    // Validar si la sección tiene un array de campos
                    if (seccion.campos && Array.isArray(seccion.campos)) {
                        // Buscar el campo por el slug
                        const campo = seccion.campos.find((campo) => campo.slug === slug);
                        if (campo) {
                            return campo.valor || "N/A"; // Retorna el valor o "N/A" si está vacío
                        }
                    }
                }
            }

            console.error("El parámetro 'orden' no tiene la estructura esperada:", orden);
            return "N/A"; // Valor predeterminado si no se encuentra
        },
        searchData() {
            const params = new URLSearchParams();
            if (this.search.dpi) params.append("dpi", this.search.dpi);
            if (this.search.sku) params.append("sku", this.search.sku);
            if (this.search.certificado) params.append("certificado", this.search.certificado);

            const searchUrl = `orders/dpi-buscar/reimprimir?${params.toString()}`;
            API.send("GET", searchUrl, {}, (response) => {
                this.orders = response.data;
            });
        },
        selectOrder(index) {
            const selectedOrder = this.orders[index];

            // Verificar si la orden tiene la estructura esperada
            if (selectedOrder && selectedOrder.secciones && Array.isArray(selectedOrder.secciones)) {
                // Asignar las secciones al formulario
                this.form = selectedOrder.secciones.map((seccion) => ({
                    seccion: seccion.seccion,
                    campos: seccion.campos.map((campo) => ({
                        label: campo.label || "",
                        valor: campo.valor || "",
                        slug: campo.slug || "",
                    })),
                }));

                this.formLoaded = true;
            } else {
                console.error("La orden seleccionada no tiene la estructura esperada:", selectedOrder);
                this.form = [];
                this.formLoaded = false;
            }
        },
        saveData() {
            // Preparar los datos para enviar al backend
            const idCotizacionField = this.form
                .flatMap((seccion) => seccion.campos) // Combina todos los campos de las secciones
                .find((campo) => campo.slug === 'id-cotizacion'); // Busca el campo con el slug 'id-cotizacion'

            const idCotizacion = idCotizacionField ? idCotizacionField.valor : null; // Toma su valor o asigna null si no se encuentra
            const payload = {
                idCotizacion: idCotizacion,
                formulario: this.form.map((seccion) => ({
                    seccion: seccion.seccion,
                    campos: seccion.campos.map((campo) => ({
                        slug: campo.slug,
                        valor: campo.valor,
                    })),
                })),
            };

            // Validar que exista un ID antes de enviar
            if (!payload.idCotizacion) {
                API.showErrorNotify("El ID de la cotización no está definido.");
                return;
            }

            // Llamar a la API con el payload
            API.send(
                "POST",
                "orders/cotizacion/guardar-cambios",
                payload,
                (response) => {
                    API.showSuccessNotify("Guardado correctamente.");
                },
                (error) => {
                    console.error("Error al guardar:", error);
                    API.showErrorNotify("Error al guardar los datos.");
                }
            );
        },
        resetForm() {
            this.form = [];
            this.formLoaded = false;
        },
        confirmDelete(index) {
            this.selectedOrderIndex = index;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedOrderIndex = null;
        },
        deleteOrder() {
            // Validar si hay órdenes y si el índice seleccionado es válido
            if (!this.orders || this.selectedOrderIndex < 0 || this.selectedOrderIndex >= this.orders.length) {
                console.error("No se encontró la orden seleccionada o el índice es inválido.");
                return;
            }

            // Obtener la orden seleccionada
            const selectedOrder = this.orders[this.selectedOrderIndex];
            if (!selectedOrder) {
                console.error("No se encontró la orden seleccionada.");
                return;
            }

            // Validar si 'selectedOrder' tiene el formato esperado para iterar
            const infoOrden = this.getCampoValor(selectedOrder, 'id-cotizacion');
            if (!infoOrden) {
                console.error("No se pudo encontrar el ID de la cotización en la orden seleccionada:", selectedOrder);
                return;
            }

            // Construir la URL para eliminar la cotización
            const deleteUrl = `orders/cotizacion/delete/${infoOrden}`;
            API.send("DELETE", deleteUrl, {}, () => {
                // Validar el índice antes de eliminar
                if (this.selectedOrderIndex >= 0 && this.selectedOrderIndex < this.orders.length) {
                    this.orders.splice(this.selectedOrderIndex, 1); // Eliminar la orden de la lista
                } else {
                    console.error("El índice de la orden seleccionada no es válido:", this.selectedOrderIndex);
                }
                this.closeModal(); // Cerrar el modal
            });
        },
        getInputType(valor) {
            if (typeof valor === "string" && /^\d{4}-\d{2}-\d{2}$/.test(valor)) return "date";
            if (typeof valor === "number") return "number";
            return "input";
        },
        loadProductos() {
            const urlToSearch = `products/all/1/1/10/0`;
            API.send("GET", urlToSearch, {}, (response) => {
                this.productos = response.data || [];
            });
        },
    },
    mounted() {
        this.loadProductos();
    },
};
</script>

<style scoped>
/* Estilo base para los botones */
.btn {
    margin-right: 0.5rem;
    border-radius: 6px;
    font-size: 0.8rem;
    padding: 0.1rem 0.1rem;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-success {
    background-color: #28a745;
    color: white;
    border-color: #28a745;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
    border-color: #6c757d;
}

.btn-danger {
    background-color: #dc3545;
    color: white;
    border-color: #dc3545;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.btn:hover {
    background-color: transparent;
    color: currentColor;
    border-color: currentColor;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn i {
    font-size: 1.6rem;
    position: relative;
    top: -1px;
}

/* Estilos de los inputs tipo cuaderno */
.form-control {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid var(--primary-color) !important;
    box-shadow: none !important;
    font-family: 'Courier New', Courier, monospace;
    color: #000;
    padding: 0.1rem 0.1rem;
    transition: border-color 0.3s ease !important;
}

.form-control:focus {
    border-bottom: 3px solid var(--primary-color) !important;
    outline: none !important;
}

/* Placeholder de los inputs */
.form-control::placeholder {
    color: #999;
    font-style: italic;
}

/* Etiquetas */
.form-label {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.75rem;
    color: #6c757d;
    font-weight: bold;
    margin-top: 0.1rem;
}

/* Estilo para los selects */
.input-group .form-select {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0.5rem !important;
    color: #000;
}

.input-group .form-select:focus {
    outline: none !important;
    box-shadow: none !important;
    border-bottom: 2px solid var(--primary-color) !important;
}

/* Sombra y estilo de grupo de input */
.input-group-text {
    border: none !important;
    background-color: transparent;
    padding: 0.5rem;
}

.input-group {
    align-items: center;
    gap: 0.5rem;
    border-bottom: 2px solid var(--primary-color) !important;
}
</style>