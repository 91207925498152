<template>
    <layout :active="1" :title="true">
        <div class="content-body">
            <div class="container" v-if="AuthGetUserInfo.layout === 'admin'">
                <div class="row justify-content-center">
                    <div class="col-xl-8">
                        <div class="card">
                            <div class="card-header">
                                <h5>Progreso del Procesamiento</h5>
                            </div>
                            <div class="card-body text-center">
                                <p>{{ progressMessage }}</p>
                                <div class="progress" style="height: 25px;">
                                    <div
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            :class="{'bg-success': progress === 100, 'bg-primary': progress < 100}"
                                            :style="{ width: progress + '%' }"
                                            role="progressbar"
                                            :aria-valuenow="progress"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                    >
                                        {{ progress }}%
                                    </div>
                                </div>
                                <p class="mt-3" v-if="progress < 100">
                                    Tiempo estimado restante: {{ timeRemaining }}
                                </p>
                                <button
                                        v-if="!loading && !completed"
                                        @click="startProcessing"
                                        class="btn btn-primary mt-3"
                                >
                                    Iniciar Proceso
                                </button>
                                <div v-if="completed" class="mt-3">
                                    <p class="text-success">¡Proceso completado con éxito!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue"; // Tu componente de layout
import API from "/src/core/Api"; // Tu API personalizada
import { mapGetters } from "vuex"; // Para obtener datos de Vuex

export default {
    name: "ProgressBar",
    components: {
        Layout, // Componente de layout
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: "AuthGetUserInfo",
        }),
        timeRemaining() {
            return this.calculateTimeRemaining();
        },
    },
    data() {
        return {
            total: 0, // Total de registros
            processed: 0, // Registros procesados
            progress: 0, // Porcentaje de progreso
            loading: false, // Indicador de carga
            completed: false, // Indicador de finalización
            progressMessage: "Preparando para iniciar el proceso...", // Mensaje dinámico
            limit: 25, // Registros por lote
            batchStartTime: null, // Tiempo de inicio del lote actual
            estimatedTimePerBatch: null, // Tiempo promedio por lote
        };
    },
    methods: {
        fetchTotal() {
            return new Promise((resolve, reject) => {
                API.send(
                    "GET",
                    "stores/registros/total",
                    {},
                    (response) => resolve(response.data),
                    (error) => reject(error)
                );
            });
        },

        processBatch(offset, limit) {
            return new Promise((resolve, reject) => {
                API.send(
                    "POST",
                    "stores/registros/procesar",
                    {offset, limit},
                    (response) => resolve(response.data.processed),
                    (error) => reject(error)
                );
            });
        },

        // Formatear tiempo en formato HH:MM:SS
        formatTime(seconds) {
            const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
            const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
            const s = Math.floor(seconds % 60).toString().padStart(2, "0");
            return `${h}:${m}:${s}`;
        },

        // Calcular tiempo estimado restante
        calculateTimeRemaining() {
            if (!this.estimatedTimePerBatch || this.processed === 0) return "Calculando...";
            const batchesRemaining = Math.ceil((this.total - this.processed) / this.limit);
            const secondsRemaining = batchesRemaining * this.estimatedTimePerBatch;
            return this.formatTime(secondsRemaining);
        },

        async startProcessing() {
            try {
                this.loading = true;
                this.completed = false;
                this.progressMessage = "Obteniendo total de registros...";
                this.total = await this.fetchTotal();
                this.processed = 0;
                this.estimatedTimePerBatch = null;

                while (this.processed < this.total) {
                    this.batchStartTime = performance.now();

                    this.progressMessage = `Procesando lote ${Math.ceil(
                        this.processed / this.limit + 1
                    )} de ${Math.ceil(this.total / this.limit)}`;
                    const processed = await this.processBatch(this.processed, this.limit);

                    this.processed += processed;
                    this.progress = Math.min(
                        100,
                        Math.round((this.processed / this.total) * 100)
                    );

                    // Calcular tiempo promedio por lote
                    const batchDuration = (performance.now() - this.batchStartTime) / 1000; // En segundos
                    if (!this.estimatedTimePerBatch) {
                        this.estimatedTimePerBatch = batchDuration;
                    }
                    else {
                        this.estimatedTimePerBatch =
                            (this.estimatedTimePerBatch + batchDuration) / 2;
                    }
                }

                this.loading = false;
                this.completed = true;
                this.progressMessage = "¡Proceso completado!";
            } catch (error) {
                console.error("Error durante el procesamiento:", error);
                this.loading = false;
                this.progressMessage = "Se produjo un error durante el proceso.";
            }
        },
    },

};
</script>

<style>
.progress {
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    transition: width 0.4s ease;
}
</style>