import localDisk from 'src/localDisk';
import API from "src/core/Api";

export const medicoState = {
    state: {
        medicoFichaActive: localDisk.read('medicoFichaActive'),
        reloadFicha: false,
        historyFiltred: localDisk.read('historyFiltred') || {},
        clienteMetada: localDisk.read('clienteMetada') || {},
    },
    mutations: {
        // categorias
        MutateMedicoFichaActive(state, data) {
            if (typeof data.metadata === 'undefined') {
                data.metadata = {};
            }
            state.medicoFichaActive = data;
            localDisk.write('medicoFichaActive', state.medicoFichaActive);
        },
        MutateReloadFicha(state, data) {
            state.reloadFicha = data;
        },
        MutateHistoryFiltred(state, data) {
            state.historyFiltred = data;
            localDisk.write('historyFiltred', state.historyFiltred);
        },
        MergeClienteMetada(state, data) {
            Object.keys(data).forEach(key => {
                state.clienteMetada[key] = data[key];
            });
            localDisk.write('clienteMetada', state.clienteMetada);
        },
        MutateClienteMetada(state, data) {
            state.clienteMetada = data;
            localDisk.write('clienteMetada', state.clienteMetada);
        },
    },
    getters: {
        GetMedicoFichaActive: state => {
            if (!state.medicoFichaActive) {
                return {};
            }
            else {
                return state.medicoFichaActive;
            }
        },
        GetReloadFicha: state => state.reloadFicha,
        GetHistoryFiltred: state => state.historyFiltred,
        GetClienteMetada: state => state.clienteMetada,
    },
    actions: {
        // Categories
        SetMedicoFichaActive: ({commit}, data) => {
            commit('MutateMedicoFichaActive', data);
        },
        SetReloadFicha: ({commit}, data) => {
            commit('MutateReloadFicha', data);
        },
        SetClienteMetada: ({commit}, data) => {
            commit('MutateClienteMetada', data);
        },
        FetchHistoryFiltred: ({commit, getters}, slugs) => {
            commit('MutateReloadFicha', true);
            const clienteId = getters.GetMedicoFichaActive.cliente.id;
            API.send('POST', 'medico/getHistoryFiltred', {
                clienteId,
                slugs,
            }, function (response) {
                // API.showSuccessAlert(response.msg);
                commit('MutateHistoryFiltred', response.data);
                commit('MutateReloadFicha', false);
            }, function (response) {
                API.showErrorAlert(response.msg);
                commit('MutateReloadFicha', false);
            });
        },
        FetchClienteMetadaBySlug: ({commit, getters}, data) => {
            commit('MutateReloadFicha', true);
            const clienteId = getters.GetMedicoFichaActive.cliente.id;
            API.send('POST', 'clients/metadata/get-by-slug', {
                clienteId,
                slugs: data.slugs
            }, function (response) {
                if (data.merge) {
                    commit('MergeClienteMetada', response.data);
                } else {
                    commit('MutateClienteMetada', response.data);
                }
                commit('MutateReloadFicha', false);
            }, function (response) {
                API.showErrorAlert(response.msg);
                commit('MutateReloadFicha', false);
            });
        },
        FetchClienteMetadaByClient: ({commit, getters}) => {
            commit('MutateReloadFicha', true);
            const clienteId = getters.GetMedicoFichaActive.cliente.id;
            API.send('POST', 'clients/metadata/get-by-client', { clienteId }, function (response) {
                // API.showSuccessAlert(response.msg);
                commit('MutateClienteMetada', response.data);
                commit('MutateReloadFicha', false);
            }, function (response) {
                API.showErrorAlert(response.msg);
                commit('MutateReloadFicha', false);
            });
        },
        SaveClienteMetada: ({commit}, data) => {
            API.send('POST', 'clients/metadata/save', data.payload, function (response) {
                API.showSuccessAlert(response.msg);
                if (data.merge) {
                    commit('MergeClienteMetada', response.data);
                } else {
                    commit('MutateClienteMetada', response.data);
                }
            }, function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        DeleteClienteMetadaDetail: ({dispatch, commit, getters}, data) => {
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                commit('MutateReloadFicha', true);
                API.send('POST', 'clients/metadata/delete-detail', {
                    metadataId: data.metadataId
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    if (data.slug) {
                        dispatch('FetchClienteMetadaBySlug', {slugs:[data.slug], merge: true});
                    } else {
                        commit('MutateReloadFicha', false);
                    }
                }, function (response) {
                    API.showErrorAlert(response.msg);
                    commit('MutateReloadFicha', false);
                });
            })
        }
    }
}
